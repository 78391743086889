import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { Bar } from "react-chartjs-2";
import DatePicker from "./DatePicker";
import { endOfDay } from "date-fns";
import PulseLoader from "react-spinners/PulseLoader";

export default function BarGraph({ deviceID, inputID, closeGraph, inputName }) {
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [logs, setLogs] = useState();
  const [noLogs, setNoLogs] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [chartData, setChartData] = useState();
  const [mode, setMode] = useState("runCount");
  const [showChart, setShowChart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphOptions, setGraphOptions] = useState();

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  function handleRangeChange(rangeStart, rangeEnd) {
    setStart(rangeStart);
    setEnd(rangeEnd);
  }

  async function getLogs() {
    let endParam;
    if (!start) {
      return;
    } else if (start && !end) {
      endParam = endOfDay(start);
      setEnd(endParam);
    } else {
      endParam = end;
    }
    setLoading(true);
    try {
      const res = await axios.get(
        `${serverAddress}/logs/showLogs/${deviceID}/${inputID}/pump/${start}/${endParam}`,
        headers
      );
      setLogs(res.data);
    } catch (e) {
      console.log("error getting logs: ", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    console.log(logs);
    if (logs && logs.length > 0) {
      setGraphOptions({
        layout: {
          padding: 20,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              type: "time",
              time: {
                unit: "day",
              },
              distribution: "linear",
              offset: true,
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: mode === "runCount" ? "Run Count" : "Run Time",
              },
              ticks: {
                stepSize: 1,
                suggestedMin: Math.floor(
                  Math.min(
                    ...logs.map((log) =>
                      log.run_count === 0 ? 0 : log.run_count - 1
                    )
                  )
                ),
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      });
      if (mode === "runCount") {
        setChartData({
          labels: logs.map((log) =>
            new Date(log.time_created).toLocaleDateString()
          ),
          datasets: [
            {
              label: "Start Count",
              data: logs.map((log) => log.run_count),
              backgroundColor: "#00b800",
              barPercentage: 1,
              categoryPercentage: 0.9,
            },
          ],
        });
      } else {
        setChartData({
          labels: logs.map((log) =>
            new Date(log.time_created).toLocaleDateString()
          ),
          datasets: [
            {
              label: "Run Time",
              data: logs.map((log) => log.run_time),
              backgroundColor: "#00b800",
            },
          ],
        });
      }
    } else if (logs && logs.length === 0) {
      setNoLogs(true);
    } else {
      setChartData();
    }
  }, [logs, mode]);

  useEffect(() => {
    if (chartData) {
      setShowChart(true);
    } else if (showChart) {
      setShowChart(false);
    }
  }, [chartData]);

  return (
    <div>
      {showChart && (
        <div className="modal">
          <div className="modal-content">
            <div
              className="chartBackButton"
              onClick={() => setShowChart(false)}
            >
              Back
            </div>
            <div className="barGraphContainer">
              <Bar data={chartData} options={graphOptions} />
            </div>
          </div>
        </div>
      )}
      <div>{inputName}</div>
      <div>Select Date Range</div>
      <DatePicker onRangeChange={handleRangeChange} />
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={12}
        className="smallLoader"
      />
      {!loading && (
        <div className="logsFilterBottomButton" onClick={getLogs}>
          Search
        </div>
      )}
      <div className="logsFilterBottomButton" onClick={closeGraph}>
        Close
      </div>
    </div>
  );
}
