import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { Line } from "react-chartjs-2";
import "react-day-picker/dist/style.css";
import DatePicker from "./DatePicker";
import { endOfDay } from "date-fns";
import PulseLoader from "react-spinners/PulseLoader";
import TimePicker from "./TimePicker";

export default function LineChart({
  deviceID,
  inputType,
  inputID,
  closeChart,
  inputName,
}) {
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [logs, setLogs] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [chartData, setChartData] = useState();
  const [mode, setMode] = useState("runCount");
  const [showChart, setShowChart] = useState(false);
  const [noLogs, setNoLogs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeFilterStartHour, setTimeFilterStartHour] = useState(12);
  const [timeFilterStartMinute, setTimeFilterStartMinute] = useState(0);
  const [timeFilterStartAmPm, setTimeFilterStartAmPm] = useState("AM");
  const [timeFilterEndHour, setTimeFilterEndHour] = useState(12);
  const [timeFilterEndMinute, setTimeFilterEndMinute] = useState(0);
  const [timeFilterEndAmPm, setTimeFilterEndAmPm] = useState("AM");

  useEffect(() => {
    currentUser.getIdToken().then((token) => {
      setToken(token);
    });
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (logs && inputType === "pressure") {
      filterLogsByTime();
    }
  }, [
    timeFilterStartHour,
    timeFilterStartMinute,
    timeFilterStartAmPm,
    timeFilterEndHour,
    timeFilterEndMinute,
    timeFilterEndAmPm,
  ]);

  function filterLogsByTime() {
    const startHour =
      timeFilterStartAmPm === "PM" && timeFilterStartHour !== 12
        ? timeFilterStartHour + 12
        : timeFilterStartAmPm === "AM" && timeFilterStartHour === 12
        ? 0
        : timeFilterStartHour;

    const endHour =
      timeFilterEndAmPm === "PM" && timeFilterEndHour !== 12
        ? timeFilterEndHour + 12
        : timeFilterEndAmPm === "AM" && timeFilterEndHour === 12
        ? 0
        : timeFilterEndHour;

    const startDateAndTime = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      startHour,
      timeFilterStartMinute
    );

    const endDateAndTime = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      endHour,
      timeFilterEndMinute
    );

    const filteredLogs = logs.filter((log) => {
      const logTime = new Date(log.timestamp);
      return logTime >= startDateAndTime && logTime <= endDateAndTime;
    });

    setChartData({
      labels: filteredLogs.map((log) =>
        new Date(log.timestamp).toLocaleString()
      ),
      datasets: [
        {
          label: "PSI",
          data: filteredLogs.map((log) => log.current_value),
          fill: false,
          borderColor: "#00b800",
          tension: 0.1,
          pointRadius: 5,
          pointHoverRadius: 10,
        },
      ],
    });
  }

  function handleRangeChange(rangeStart, rangeEnd) {
    setStart(rangeStart);
    setEnd(rangeEnd);
  }

  async function getLogs() {
    let endParam;
    if (!start) {
      return;
    } else if (start && !end) {
      endParam = endOfDay(start);
      setEnd(endParam);
    } else {
      endParam = end;
    }
    setLoading(true);
    try {
      const res = await axios.get(
        `${serverAddress}/logs/showLogs/${deviceID}/${inputID}/${inputType}/${start}/${endParam}`,
        headers
      );
      setLogs(res.data);
      setTimeFilterStartHour(12);
      setTimeFilterStartMinute(0);
      setTimeFilterStartAmPm("AM");
      setTimeFilterEndHour(11);
      setTimeFilterEndMinute(59);
      setTimeFilterEndAmPm("PM");
    } catch (e) {
      console.log("error getting logs: ", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (logs && logs.length > 0) {
      if (inputType === "pressure") {
        setChartData({
          labels: logs.map((log) => new Date(log.timestamp).toLocaleString()),
          datasets: [
            {
              label: "PSI",
              data: logs.map((log) => log.current_value),
              fill: false,
              borderColor: "#00b800",
              tension: 0.1,
              pointRadius: 5,
              pointHoverRadius: 10,
            },
          ],
        });
      } else if (inputType == "pump") {
        // can get rid of this else if block after finishing bar graph
        if (mode === "runCount") {
          setChartData({
            labels: logs.map((log) =>
              new Date(log.time_created).toLocaleDateString()
            ),
            datasets: [
              {
                label: "Start Count",
                data: logs.map((log) => log.run_count),
                fill: false,
                borderColor: "#00b800",
                tension: 0.1,
                pointRadius: 5,
                pointHoverRadius: 10,
              },
            ],
          });
        } else {
          setChartData({
            labels: logs.map((log) =>
              new Date(log.time_created).toLocaleDateString()
            ),
            datasets: [
              {
                label: "Run Time",
                data: logs.map((log) => log.run_time),
                fill: false,
                borderColor: "#00b800",
                tension: 0.1,
                pointRadius: 5,
                pointHoverRadius: 10,
              },
            ],
          });
        }
      }
    } else if (logs && logs.length === 0) {
      setNoLogs(true);
    } else {
      setChartData();
    }
  }, [logs, mode]);

  useEffect(() => {
    if (chartData) {
      setShowChart(true);
    } else if (showChart) {
      setShowChart(false);
    }
  }, [chartData]);

  const chartOptions = {
    layout: {
      padding: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true,
          type: "time",
          time: {
            unit: "minute",
          },
          distribution: "series", // This was linear but I'm trying series
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "PSI",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  return (
    <div>
      {showChart && (
        <div className="modal">
          <div className="modal-content">
            <div
              className="chartBackButton"
              onClick={() => setShowChart(false)}
            >
              Back
            </div>
            <div className="flex-align-row-wrap">
              <div className="flex-align-row">
                <div className="chartTopSectionItem">From:</div>
                <div>{start.toLocaleDateString()}</div>
                <TimePicker
                  hour={timeFilterStartHour}
                  minute={timeFilterStartMinute}
                  period={timeFilterStartAmPm}
                  onHourChange={setTimeFilterStartHour}
                  onMinuteChange={setTimeFilterStartMinute}
                  onPeriodChange={setTimeFilterStartAmPm}
                />
              </div>
              <div className="flex-align-row">
                <div className="chartTopSectionItem">To:</div>
                <div>{end.toLocaleDateString()}</div>
                <TimePicker
                  hour={timeFilterEndHour}
                  minute={timeFilterEndMinute}
                  period={timeFilterEndAmPm}
                  onHourChange={setTimeFilterEndHour}
                  onMinuteChange={setTimeFilterEndMinute}
                  onPeriodChange={setTimeFilterEndAmPm}
                />
              </div>
            </div>
            <div className="chartContainer">
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      )}
      {noLogs && (
        <div className="modal">
          <div className="modal-content">
            <div className="middleContainer">
              <div className="margin-t-b-1">No logs for selected range</div>
              <div className="chartBackButton" onClick={() => setNoLogs(false)}>
                Ok
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{inputName}</div>
      <div>Select Date Range</div>
      <DatePicker onRangeChange={handleRangeChange} />
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={12}
        className="smallLoader"
      />
      {!loading && (
        <div className="logsFilterBottomButton" onClick={getLogs}>
          Search
        </div>
      )}
      <div className="logsFilterBottomButton" onClick={closeChart}>
        Close
      </div>
    </div>
  );
}
