import { useState, useEffect } from "react";

export default function TimePicker({
  hour,
  minute,
  period,
  onHourChange,
  onMinuteChange,
  onPeriodChange,
}) {
  const [showHourOptions, setShowHourOptions] = useState(false);
  const [showMinuteOptions, setShowMinuteOptions] = useState(false);
  const [showPeriodOptions, setShowPeriodOptions] = useState(false);

  useEffect(() => {
    if (showHourOptions) {
      if (showMinuteOptions) {
        setShowMinuteOptions(false);
      }

      if (showPeriodOptions) {
        setShowPeriodOptions(false);
      }
    }
  }, [showHourOptions]);

  useEffect(() => {
    if (showMinuteOptions) {
      if (showHourOptions) {
        setShowHourOptions(false);
      }

      if (showPeriodOptions) {
        setShowPeriodOptions(false);
      }
    }
  }, [showMinuteOptions]);

  useEffect(() => {
    if (showPeriodOptions) {
      if (showHourOptions) {
        setShowHourOptions(false);
      }

      if (showMinuteOptions) {
        setShowMinuteOptions(false);
      }
    }
  }, [showPeriodOptions]);

  function handleSelection(mode, selection) {
    if (mode === "hour") {
      onHourChange(selection);
      setShowHourOptions(false);
    } else if (mode === "minute") {
      onMinuteChange(Number(selection));
      setShowMinuteOptions(false);
    } else {
      onPeriodChange(selection);
      setShowPeriodOptions(false);
    }
  }

  return (
    <div className="timePickerWrapper">
      <div className="flex-align-row">
        <div className="timePickerContainer">
          <div
            className={`timePickerActiveSelection ${
              showHourOptions ? "selected" : ""
            }`}
            onClick={() => setShowHourOptions(!showHourOptions)}
          >
            {hour}
          </div>
          {showHourOptions && (
            <div className="timePickerOptions">
              {Array.from({ length: 12 }, (_, i) => i + 1).map((hourOption) => (
                <div
                  className={`timePickerOption ${
                    hourOption === hour ? "selected" : ""
                  }`}
                  key={hourOption}
                  onClick={() => handleSelection("hour", hourOption)}
                >
                  {hourOption}
                </div>
              ))}
            </div>
          )}
        </div>
        <div>:</div>
        <div className="timePickerContainer">
          <div
            className={`timePickerActiveSelection ${
              showMinuteOptions ? "selected" : ""
            }`}
            onClick={() => setShowMinuteOptions(!showMinuteOptions)}
          >
            {minute.toString().padStart(2, "0")}
          </div>
          {showMinuteOptions && (
            <div className="timePickerOptions">
              {[0, 15, 30, 45, 59].map((minuteOption) => (
                <div
                  className={`timePickerOption ${
                    minuteOption === minute ? "selected" : ""
                  }`}
                  key={minuteOption}
                  onClick={() => handleSelection("minute", minuteOption)}
                >
                  {minuteOption.toString().padStart(2, "0")}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="timePickerContainer">
          <div
            className={`timePickerActiveSelection ${
              showPeriodOptions ? "selected" : ""
            }`}
            onClick={() => setShowPeriodOptions(!showPeriodOptions)}
          >
            {period}
          </div>
          {showPeriodOptions && (
            <div className="timePickerOptions">
              <div
                className={`timePickerOption ${
                  period === "AM" ? "selected" : ""
                }`}
                onClick={() => handleSelection("period", "AM")}
              >
                AM
              </div>
              <div
                className={`timePickerOption ${
                  period === "PM" ? "selected" : ""
                }`}
                onClick={() => handleSelection("period", "PM")}
              >
                PM
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
