import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleNotch,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

export default function InputDisplay({ i, updateSelectedForBarGraph }) {
  const todaysDate = new Date();
  const pumpRunDate = todaysDate.toLocaleDateString();

  function displayInput(i) {
    return (
      <div className="sectionSpacer">
        <div className="inputName">{i.name}</div>
        {i.alert_status ? (
          <div>
            {i.type === "pump" ? (
              <div>
                <div className="okStatusGreen">
                  <FontAwesomeIcon className="okStatusCircle" icon={faCircle} />
                  {i.alert_message}
                </div>
                {i.display_run_count && (
                  <div className="marginTop">
                    {i.run_count_date === pumpRunDate ? (
                      <div>
                        <div>Today's Start Count: {i.daily_run_count}</div>
                        {i.run_time && (
                          <div className="sectionSpacer4">
                            Today's Run Time: {i.run_time}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div>Today's Start Count: 0</div>
                        {i.run_time && (
                          <div className="sectionSpacer4">
                            Today's Run Time: 00:00:00
                          </div>
                        )}
                      </div>
                    )}
                    <FontAwesomeIcon
                      className="logsBtn"
                      icon={faChartLine}
                      onClick={() =>
                        updateSelectedForBarGraph({
                          id: i.input_id,
                          name: i.name,
                          type: i.type,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="emStatus">
                <FontAwesomeIcon className="emStatusCircle" icon={faCircle} />
                {i.alert_message}
              </div>
            )}
          </div>
        ) : (
          <div>
            {i.type === "pump" ? (
              <div>
                <div className="okStatus">
                  <FontAwesomeIcon
                    className="okStatusCircle"
                    icon={faCircleNotch}
                  />
                  {i.ok_message}
                </div>
                {i.display_run_count && (
                  <div className="marginTop">
                    {i.run_count_date === pumpRunDate ? (
                      <div>
                        <div>Today's Start Count: {i.daily_run_count}</div>
                        {i.run_time && (
                          <div className="sectionSpacer4">
                            Today's Run Time: {i.run_time}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div>Today's Start Count: 0</div>
                        {i.run_time && (
                          <div className="sectionSpacer4">
                            Today's Run Time: 00:00:00
                          </div>
                        )}
                      </div>
                    )}
                    <FontAwesomeIcon
                      className="logsBtn"
                      icon={faChartLine}
                      onClick={() =>
                        updateSelectedForBarGraph({
                          id: i.input_id,
                          name: i.name,
                          type: i.type,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="okStatus">
                  <FontAwesomeIcon className="okStatusCircle" icon={faCircle} />
                  {i.ok_message}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return <div key={i.input_id}>{displayInput(i)}</div>;
}
