import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleNotch,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase/app";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { UserContext } from "../UserContext";
import PulseLoader from "react-spinners/PulseLoader";
import InputConfig from "./InputConfig";
import LineChart from "./LineChart";
import BarGraph from "./BarGraph";

export default function Status({ pumpRoomRef }) {
  const [device, setDevice] = useState();
  const [pumps, setPumps] = useState([]);
  const [switches, setSwitches] = useState([]);
  const [customs, setCustoms] = useState([]);
  const [pressureDevices, setPressureDevices] = useState([]);
  const [tempDevices, setTempDevices] = useState([]);
  const [voltageDevices, setVoltageDevices] = useState([]);
  const [miscInputs, setMiscInputs] = useState([]);
  const [token, setToken] = useState();
  const [headers, setHeaders] = useState();
  const currentUser = useAuth().currentUser;
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  const [tanks, setTanks] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [alarmBanks, setAlarmBanks] = useState([]);
  const [noAlarms, setNoAlarms] = useState(true);
  const [modbusPressureDevices, setModbusPressureDevices] = useState([]);
  const [userRef, setUserRef] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [lastComm, setLastComm] = useState("");
  const [showInputConfig, setShowInputConfig] = useState(false);
  const [selectedForLogs, setSelectedForLogs] = useState();
  const [selectedForBarGraph, setSelectedForBarGraph] = useState();

  const todaysDate = new Date();
  const pumpRunDate = todaysDate.toLocaleDateString();

  // useEffect(() => {
  //   const inputs = ["tc00", "tc01", "tc02", "tc03", "tc04"];
  //   inputs.forEach((i) => {
  //     db.collection("devices")
  //       .doc("RM1000")
  //       .collection("inputs")
  //       .doc(i)
  //       .update({
  //         enableLowTemp: false,
  //         lowTemp: 0,
  //         enableHighTemp: false,
  //         highTemp: 0,
  //         enableLowPressure: false,
  //         lowPressure: 0,
  //         enableHighPressure: false,
  //         highPressure: 0,
  //         enableLowLevel: false,
  //         lowLevel: 0,
  //         enableHighLevel: false,
  //         highLevel: 0,
  //         tankLevel: 0,
  //       });
  //   });
  // }, []);

  useEffect(() => {
    if (device) {
      const commDate = new Date(device.lastCommTimestamp.toDate());
      setLastComm(
        new Date(commDate).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );
    }
  }, [device]);

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        setToken(token);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      setHeaders({
        headers: {
          token: token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("alarms")
        .onSnapshot((snapshot) => {
          setAlarmBanks(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    setNoAlarms(true);
    if (alarmBanks.length > 0) {
      alarmBanks.forEach((b) => {
        if (b.alarms !== null) {
          setNoAlarms(false);
        }
      });
    }
  }, [alarmBanks]);

  function displayAlarm(b) {
    if (b.alarms !== null) {
      return b.alarms.map((a) => {
        return (
          <div className="emStatus">
            <FontAwesomeIcon className="emStatusCircle" icon={faCircle} />
            {a}
          </div>
        );
      });
    }
    return null;
  }

  const closeChart = () => {
    setSelectedForLogs();
  };

  const closeGraph = () => {
    setSelectedForBarGraph();
  };

  useEffect(() => {
    setTimeout(() => {
      if (pumpRoomRef.monitoring_enabled === 1) {
        const unsubscribe = db
          .collection("devices")
          .doc(pumpRoomRef.device)
          .onSnapshot((doc) => {
            setDevice(doc.data());
            setLoading(false);
          });

        return () => {
          unsubscribe();
        };
      } else {
        setLoading(false);
      }
    }, [200]);
  }, [pumpRoomRef]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "pump")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setPumps(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "switchPos")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setSwitches(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "custom")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setCustoms(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "pressure")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setPressureDevices(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "tank")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setTanks(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "temp")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setTempDevices(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "voltage")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setVoltageDevices(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("inputs")
        .where("enabled", "==", true)
        .where("type", "==", "modbusPressure")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          setModbusPressureDevices(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      const unsubscribe = db
        .collection("devices")
        .doc(device.id)
        .collection("miscInputs")
        .orderBy("label")
        .onSnapshot((snapshot) => {
          setMiscInputs(snapshot.docs.map((doc) => doc.data()));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [device]);

  function modbusPressureStyleSetter(d) {
    if (d.system <= d.cutIn) {
      return (
        <div>
          <div className="redSystemPSI">{d.system}</div>
          <div className="systemPSIlabel">SYSTEM</div>
        </div>
      );
    } else if (d.system <= d.cutOut) {
      return (
        <div>
          <div className="yellowSystemPSI">{d.system}</div>
          <div className="systemPSIlabel">SYSTEM</div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="greenSystemPSI">{d.system}</div>
          <div className="systemPSIlabel">SYSTEM</div>
        </div>
      );
    }
  }

  function displayAnalogData(
    id,
    type,
    enableLow,
    enableHigh,
    lowPoint,
    highPoint,
    value,
    name
  ) {
    let adjustment;
    if (type === "tank") {
      adjustment = 1;
    } else {
      adjustment = 5;
    }
    if (
      (enableLow && value <= lowPoint) ||
      (enableHigh && value >= highPoint)
    ) {
      return (
        <div>
          <div className="redSystemPSI">{value}</div>
          <div className="redSystemPSIlabel">{name}</div>
          <div className="levelsFlex">
            {enableLow && <div className="setPointLabel">L {lowPoint}</div>}
            {enableHigh && <div className="setPointLabel">H {highPoint}</div>}
          </div>
          {type === "pressure" && (
            <FontAwesomeIcon
              className="logsBtn"
              icon={faChartLine}
              onClick={() =>
                setSelectedForLogs({ id: id, type: type, name: name })
              }
            />
          )}
        </div>
      );
    } else if (
      (enableLow && value <= lowPoint + adjustment) ||
      (enableHigh && value >= highPoint - adjustment)
    ) {
      return (
        <div>
          <div className="yellowSystemPSI">{value}</div>
          <div className="yellowSystemPSIlabel">{name}</div>
          <div className="levelsFlex">
            {enableLow && <div className="setPointLabel">L {lowPoint}</div>}
            {enableHigh && <div className="setPointLabel">H {highPoint}</div>}
          </div>
          {type === "pressure" && (
            <FontAwesomeIcon
              className="logsBtn"
              icon={faChartLine}
              onClick={() =>
                setSelectedForLogs({ id: id, type: type, name: name })
              }
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="greenSystemPSI">{value}</div>
          <div className="systemPSIlabel">{name}</div>
          <div className="levelsFlex">
            {enableLow && <div className="setPointLabel">L {lowPoint}</div>}
            {enableHigh && <div className="setPointLabel">H {highPoint}</div>}
          </div>
          {type === "pressure" && (
            <FontAwesomeIcon
              className="logsBtn"
              icon={faChartLine}
              onClick={() =>
                setSelectedForLogs({ id: id, type: type, name: name })
              }
            />
          )}
        </div>
      );
    }
  }

  function voltageStyleSetter(setPoint, currentValue, name, highMode) {
    if (highMode) {
      if (currentValue >= setPoint) {
        return (
          <div>
            <div className="redSystemPSI">{currentValue}</div>
            <div className="redSystemPSIlabel">{name}</div>
          </div>
        );
      } else if (currentValue >= setPoint - 5) {
        return (
          <div>
            <div className="yellowSystemPSI">{currentValue}</div>
            <div className="yellowSystemPSIlabel">{name}</div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="greenSystemPSI">{currentValue}</div>
            <div className="systemPSIlabel">{name}</div>
          </div>
        );
      }
    } else {
      if (currentValue <= setPoint) {
        return (
          <div>
            <div className="redSystemPSI">{currentValue}</div>
            <div className="redSystemPSIlabel">{name}</div>
          </div>
        );
      } else if (currentValue <= setPoint + 5) {
        return (
          <div>
            <div className="yellowSystemPSI">{currentValue}</div>
            <div className="yellowSystemPSIlabel">{name}</div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="greenSystemPSI">{currentValue}</div>
            <div className="systemPSIlabel">{name}</div>
          </div>
        );
      }
    }
  }

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedTime = new Date(timestamp).toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
  }, []);

  return (
    <div>
      <PulseLoader
        color={"#ffffff"}
        loading={loading}
        size={20}
        className="loader"
      />
      {loading === false && (
        <div>
          {selectedForLogs || selectedForBarGraph ? (
            <>
              {selectedForLogs ? (
                <LineChart
                  deviceID={pumpRoomRef.device}
                  inputType={selectedForLogs.type}
                  inputID={selectedForLogs.id}
                  closeChart={closeChart}
                  inputName={selectedForLogs.name}
                />
              ) : (
                <BarGraph
                  deviceID={pumpRoomRef.device}
                  inputID={selectedForBarGraph.id}
                  closeGraph={closeGraph}
                  inputName={selectedForBarGraph.name}
                />
              )}
            </>
          ) : (
            <>
              {showInputConfig === false ? (
                <div>
                  {pumpRoomRef.monitoring_enabled && device ? (
                    <div>
                      <div className="pumpRoomHeading2">
                        <h2>Monitoring</h2>
                        {/* {userRef.admin && (
                      <div>
                        <button
                          className="spaceBtn"
                          onClick={() => setShowInputConfig(true)}
                        >
                          INPUT CONFIG
                        </button>
                      </div>
                    )} */}
                      </div>
                      {lastComm.length > 0 && (
                        <div className="margin1">
                          <div className="bold sectionSpacer">
                            LAST CHECK-IN:
                          </div>
                          <div>{lastComm}</div>
                        </div>
                      )}
                      {noAlarms === false && (
                        <div className="sectionSpacer">
                          <div className="pumpRoomSectionRed">
                            <div className="pumpRoomHeading">
                              <h2>ALARMS</h2>
                            </div>
                            <div className="sectionSpacer">
                              {alarmBanks.map((b) => (
                                <div key={b.name}>
                                  <div>{displayAlarm(b)}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {pumps.length > 0 && (
                        <div className="sectionSpacer">
                          <div className="pumpRoomSection">
                            <div className="pumpRoomHeading">
                              <h2>PUMPS</h2>
                            </div>
                            <div className="sectionSpacer">
                              {pumps.map((pump) => (
                                <div
                                  key={pump.inputNum}
                                  className="statusSection"
                                >
                                  <div className="statusTypeHeading">
                                    {pump.name}
                                  </div>
                                  {pump.alertStatus ? (
                                    <div className="okStatusGreen">
                                      <FontAwesomeIcon
                                        className="okStatusCircle"
                                        icon={faCircle}
                                      />
                                      RUNNING
                                    </div>
                                  ) : (
                                    <div className="okStatus">
                                      <FontAwesomeIcon
                                        className="okStatusCircle"
                                        icon={faCircleNotch}
                                      />
                                      STOPPED
                                    </div>
                                  )}
                                  {pump.displayRunCount && (
                                    <div>
                                      {pump.runCountDate === pumpRunDate ? (
                                        <div>
                                          Today's Start Count:{" "}
                                          {pump.dailyRunCount}
                                        </div>
                                      ) : (
                                        <div>Today's Start Count: 0</div>
                                      )}
                                      <FontAwesomeIcon
                                        className="logsBtn"
                                        icon={faChartLine}
                                        onClick={() =>
                                          setSelectedForBarGraph({
                                            id: pump.inputNum,
                                            name: pump.name,
                                            type: pump.type,
                                          })
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {switches.length > 0 && (
                        <div className="sectionSpacer">
                          <div className="pumpRoomSection">
                            <div className="pumpRoomHeading">
                              <h2>SWITCHES</h2>
                            </div>
                            <div className="sectionSpacer">
                              {switches.map((s) => (
                                <div key={s.inputNum} className="statusSection">
                                  <div className="statusTypeHeading">
                                    {s.name}
                                  </div>
                                  {s.alertStatus && (
                                    <div className="emStatus">
                                      <FontAwesomeIcon
                                        className="emStatusCircle"
                                        icon={faCircle}
                                      />
                                      NOT IN AUTO
                                    </div>
                                  )}
                                  {s.alertStatus === false && (
                                    <div>
                                      <div className="okStatus">
                                        <FontAwesomeIcon
                                          className="okStatusCircle"
                                          icon={faCircle}
                                        />
                                        AUTO
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {customs.length > 0 && (
                        <div className="sectionSpacer">
                          <div className="pumpRoomSection">
                            <div className="pumpRoomHeading">
                              <h2>CUSTOM ALERTS</h2>
                            </div>
                            <div className="sectionSpacer">
                              {customs.map((c) => (
                                <div key={c.inputNum} className="statusSection">
                                  <div className="statusTypeHeading">
                                    {c.name}
                                  </div>
                                  {c.alertStatus && (
                                    <div className="emStatus">
                                      <FontAwesomeIcon
                                        className="emStatusCircle"
                                        icon={faCircle}
                                      />
                                      {c.alertMessage}
                                    </div>
                                  )}
                                  {c.alertStatus === false && (
                                    <div>
                                      <div className="okStatus">
                                        <FontAwesomeIcon
                                          className="okStatusCircle"
                                          icon={faCircle}
                                        />
                                        {c.okMessage}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {pressureDevices.length > 0 && (
                        <div className="psiContainer">
                          <div className="sectionSpacer">
                            <div className="pumpRoomSection">
                              <div className="pumpRoomHeading">
                                <h2>PRESSURE (PSI)</h2>
                              </div>
                              <div className="sectionSpacer">
                                <div className="statusFlexBox">
                                  {pressureDevices.map((device) => (
                                    <div
                                      className="systemPSIsectionWrapper"
                                      key={device.inputNum}
                                    >
                                      <div>
                                        {displayAnalogData(
                                          device.inputNum,
                                          device.type,
                                          device.enableLowPressure,
                                          device.enableHighPressure,
                                          device.lowPressure,
                                          device.highPressure,
                                          device.currentValue,
                                          device.name
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {tanks.length > 0 && (
                        <div className="psiContainer">
                          <div className="sectionSpacer">
                            <div className="pumpRoomSection">
                              <div className="pumpRoomHeading">
                                <h2>TANK LEVEL (FEET)</h2>
                              </div>
                              <div className="sectionSpacer">
                                <div className="statusFlexBox">
                                  {tanks.map((device) => (
                                    <div
                                      className="systemPSIsectionWrapper"
                                      key={device.inputNum}
                                    >
                                      <div>
                                        {displayAnalogData(
                                          device.inputNum,
                                          device.type,
                                          device.enableLowLevel,
                                          device.enableHighLevel,
                                          device.lowLevel,
                                          device.highLevel,
                                          device.tankLevel,
                                          device.name
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {tempDevices.length > 0 && (
                        <div className="psiContainer">
                          <div className="sectionSpacer">
                            <div className="pumpRoomSection">
                              <div className="pumpRoomHeading">
                                <h2>TEMPERATURE (F)</h2>
                              </div>
                              <div className="sectionSpacer">
                                <div className="statusFlexBox">
                                  {tempDevices.map((device) => (
                                    <div
                                      className="systemPSIsectionWrapper"
                                      key={device.inputNum}
                                    >
                                      <div>
                                        {displayAnalogData(
                                          device.inputNum,
                                          device.type,
                                          device.enableLowTemp,
                                          device.enableHighTemp,
                                          device.lowTemp,
                                          device.highTemp,
                                          device.currentValue,
                                          device.name
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {modbusPressureDevices.length > 0 && (
                        <div>
                          {modbusPressureDevices.map((d) => (
                            <div key={d.inputNum} className="psiContainer">
                              <div className="sectionSpacer">
                                <div className="remoteSection">
                                  <div className="pumpRoomHeading">
                                    <h2>{d.name}</h2>
                                  </div>
                                  <div className="modbusFlexContainer">
                                    <div className="modbusFlex">
                                      <div className="modbusMinorInfo">
                                        {d.cutIn}
                                      </div>
                                      <div>CUT IN</div>
                                    </div>
                                    <div className="modbusFlex">
                                      <div className="greenSystemPSI">
                                        {d.suction}
                                      </div>
                                      <div className="systemPSIlabel">
                                        SUCTION
                                      </div>
                                    </div>
                                    <div className="modbusFlex">
                                      {modbusPressureStyleSetter(d)}
                                    </div>
                                    <div className="modbusFlex">
                                      <div className="modbusMinorInfo">
                                        {d.cutOut}
                                      </div>
                                      <div>CUT OUT</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {voltageDevices.length > 0 && (
                        <div className="psiContainer">
                          <div className="sectionSpacer">
                            <div className="remoteSection">
                              <div className="pumpRoomHeading">
                                <h2>VOLTAGE</h2>
                              </div>
                              <div className="sectionSpacer">
                                <div className="statusFlexBox">
                                  {voltageDevices.map((device) => (
                                    <div
                                      className="systemPSIsectionWrapper"
                                      key={device.inputNum}
                                    >
                                      <div>
                                        {voltageStyleSetter(
                                          device.setPoint,
                                          device.currentValue,
                                          device.name,
                                          device.highValueMode
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* {miscInputs.length > 0 && (
                <div className="psiContainer">
                  <div className="sectionSpacer">
                    <div className="remoteSection">
                      <div className="pumpRoomHeading">
                        <h2>MISC DATA</h2>
                      </div>
                      <div className="sectionSpacer">
                        <div>
                          {miscInputs.map((input) => (
                            <div key={input.inputNum}>
                              <div>
                                {input.label}: {input.value}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                    </div>
                  ) : (
                    <div className="middleContainer">
                      NO ACTIVE REMOTE MONITORING PLAN
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="pumpRoomHeading2">
                    <h2>Input Config</h2>
                    <div>
                      <button
                        className="spaceBtn"
                        onClick={() => setShowInputConfig(false)}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                  <InputConfig device={device} pumpRoomRef={pumpRoomRef} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
