import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useBodyStyles from "../useBodyStyles";
import remoteLogo from "./rosemontremotelogoblack-01.png";
import enclosureFront from "./enclosurefrontv2.png";
import docs from "./folders.png";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import PulseLoader from "react-spinners/PulseLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import lineChartVideo from "../assets/videos/LineChart.mp4";
import barGraphVideo from "../assets/videos/BarGraph.mp4";

export default function Explore() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const history = useHistory();
  const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
  useBodyStyles({ backgroundColor: "white", color: "black" });
  const [emailSignUpError, setEmailSignUpError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestedSalesCall, setRequestedSalesCall] = useState();
  const [jockeyAlertStatus, setJockeyAlertStatus] = useState(false);
  const [jockeyRunCount, setJockeyRunCount] = useState(0);
  const [jockeyRunTime, setJockeyRunTime] = useState(0);
  const [jockeyTripped, setJockeyTripped] = useState(false);
  const [runCountSinceTripped, setRunCountSinceTripped] = useState(0);
  const [firePumpRunAlertStatus, setFirePumpRunAlertStatus] = useState(false);
  const [firePumpPowerLoss, setFirePumpPowerLoss] = useState(false);
  const [systemPSI, setSystemPSI] = useState(130);
  const [suctionPSI, setSuctionPSI] = useState(53);

  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad each value with a leading zero if it's less than 10
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSuctionPSI(Math.floor(Math.random() * (54 - 50 + 1)) + 50);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setJockeyAlertStatus((prevState) => !prevState);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (jockeyAlertStatus) {
      setJockeyTripped(false);
      setJockeyRunCount(jockeyRunCount + 1);
    } else if (jockeyRunCount > 0) {
      setJockeyRunTime(jockeyRunTime + 5);
      setRunCountSinceTripped(runCountSinceTripped + 1);
    }
    const interval = setInterval(() => {
      if (jockeyAlertStatus) {
        setSystemPSI((prevState) => prevState + 1);
      } else {
        setSystemPSI((prevState) => prevState - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [jockeyAlertStatus]);

  useEffect(() => {
    if (!jockeyAlertStatus && runCountSinceTripped > 1) {
      setRunCountSinceTripped(0);
      setJockeyTripped(true);
    }
  }, [runCountSinceTripped]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFirePumpPowerLoss((prevState) => !prevState);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (emailSignUpError) {
      setEmailSignUpError(null);
    }
  }, [email]);

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  async function signUpEmail() {
    if (emailSignUpError) {
      setEmailSignUpError(null);
    }
    if (!validateEmail(email)) {
      setEmailSignUpError("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    if (!captchaToken) {
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${serverAddress}/subscribeToEmailList`, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        captchaToken: captchaToken,
        requestedSalesCall: requestedSalesCall,
      });
      setEmailSubmitted(true);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setEmailSubmitted(true);
      } else {
        setEmailSignUpError("Error signing up for email list.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="exploreContainer">
      <div className="exploreTopFlex">
        <img className="exploreLogo" src={remoteLogo} />
        <div className="pointer" onClick={() => history.push("/")}>
          Home
        </div>
      </div>
      <div className="exploreWrapper">
        <h2>What is Rosemont Remote?</h2>
        <div className="exploreBodyText">
          Rosemont Remote is a cloud-based monitoring system designed for fire
          pump rooms and dry fire protection systems. Whether you need to
          monitor your jockey and fire pump controllers, or your compressors,
          Remote can handle it.
        </div>
        <div className="exploreBodyText">
          Remote continuously reads and records data from your system to make
          your equipment status easily accessible on a phone, computer or tablet
          via the web app.
        </div>
        <h2>What Equipment is Needed?</h2>
        <div className="exploreBodyText">
          Your pump room's equipment is integrated into a 14" x 12" x 6"
          fiberglass enclosure that houses a combination of digital and analog
          inputs.
        </div>
        <div className="exploreBodyText">
          Digital inputs connect to auxiliary contacts, such as pump run status
          or HOA switch positions, while analog inputs connect to sensors like
          pressure transducers. The data is processed within the enclosure,
          displayed on a 7-inch touchscreen, and transmitted to the cloud via
          cellular technology—eliminating the need to connect to your network.
        </div>
        <div className="exploreBodyText">
          Additionally, the touchscreen features an interactive churn test form
          that walks users through the steps of a churn test, while
          automatically recording key values like pressures and pump run
          duration.
        </div>
        <img src={enclosureFront} />
        <h2>How Do I Monitor My Equipment?</h2>
        <div className="exploreBodyText">
          The Rosemont Remote web app provides a user-friendly dashboard to
          access detailed information about your equipment.
        </div>
        <div className="exploreBodyText">
          The app is divided into rooms, like virtual pump rooms or compressor
          zones. When you select a room from your list you will see the status
          of that room's equipment.
        </div>
        {/* <img className="widePic" src={dashboard} /> */}
        <div className="exploreDarkSection">
          <div className="pumpRoomHeading2">
            <h3>Monitoring</h3>
          </div>
          <div className="sectionSpacer">
            <div className="pumpRoomSection">
              <h3>JOCKEY PUMP</h3>
              <div className="sectionSpacer">
                <div className="inputName">Run Status</div>
                <div
                  className={jockeyAlertStatus ? "okStatusGreen" : "okStatus"}
                >
                  <FontAwesomeIcon
                    className="okStatusCircle"
                    icon={jockeyAlertStatus ? faCircle : faCircleNotch}
                  />
                  {jockeyAlertStatus
                    ? "JOCKEY PUMP RUNNING"
                    : "JOCKEY PUMP STOPPED"}
                </div>
                <div className="marginTop">
                  <div>
                    <div>Today's Start Count: {jockeyRunCount}</div>
                    <div className="sectionSpacer4">
                      Today's Run Time: {formatTime(jockeyRunTime)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="sectionSpacer">
                <div className="inputName">MCP Status</div>
                <div>
                  <div className={jockeyTripped ? "emStatus" : "okStatus"}>
                    <FontAwesomeIcon
                      className={
                        jockeyTripped ? "emStatusCircle" : "okStatusCircle"
                      }
                      icon={faCircle}
                    />
                    {jockeyTripped ? "JOCKEY MCP TRIPPED" : "JOCKEY MCP OK"}
                  </div>
                </div>
              </div>
              <div className="sectionSpacer">
                <div className="inputName">Power Condition</div>
                <div>
                  <div className="okStatus">
                    <FontAwesomeIcon
                      className="okStatusCircle"
                      icon={faCircle}
                    />
                    JOCKEY POWER OK
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionSpacer">
            <div className="pumpRoomSection">
              <h3>FIRE PUMP</h3>
              <div className="sectionSpacer">
                <div className="inputName">Run Status</div>
                <div
                  className={
                    firePumpRunAlertStatus ? "okStatusGreen" : "okStatus"
                  }
                >
                  <FontAwesomeIcon
                    className="okStatusCircle"
                    icon={firePumpRunAlertStatus ? faCircle : faCircleNotch}
                  />
                  {firePumpRunAlertStatus
                    ? "FIRE PUMP RUNNING"
                    : "FIRE PUMP STOPPED"}
                </div>
              </div>
              <div className="sectionSpacer">
                <div className="inputName">Power Condition</div>
                <div>
                  <div className={firePumpPowerLoss ? "emStatus" : "okStatus"}>
                    <FontAwesomeIcon
                      className={
                        firePumpPowerLoss ? "emStatusCircle" : "okStatusCircle"
                      }
                      icon={faCircle}
                    />
                    {firePumpPowerLoss
                      ? "FIRE PUMP POWER LOSS"
                      : "FIRE PUMP POWER OK"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="psiContainer sectionSpacer">
            <div className="pumpRoomSection">
              <div className="pumpRoomHeading">
                <h3>PRESSURE (PSI)</h3>
              </div>
              <div className="sectionSpacer">
                <div className="statusFlexBox">
                  <div className="systemPSIsectionWrapper">
                    <div>
                      <div className="greenSystemPSI">{suctionPSI}</div>
                      <div className="systemPSIlabel">Suction</div>
                    </div>
                  </div>
                  <div className="systemPSIsectionWrapper">
                    <div>
                      <div className="greenSystemPSI">{systemPSI}</div>
                      <div className="systemPSIlabel">System</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2>What Can Be Monitored?</h2>
        <div className="exploreBodyText">
          Rosemont Remote offers extensive and customizable monitoring options
          tailored to your specific needs.
        </div>
        <div className="exploreBodyText bold">
          For a pump room, a typical setup monitors:
        </div>
        <div className="exploreList">
          <div className="exploreListItem">- Fire pump run status</div>
          <div className="exploreListItem">- Jockey pump run status</div>
          <div className="exploreListItem">- System Pressure</div>
        </div>
        <div className="exploreBodyText">Extra monitoring options include:</div>
        <div className="exploreList">
          <div className="exploreListItem">- Jockey pump switch position</div>
          <div className="exploreListItem">- Power loss</div>
          <div className="exploreListItem">- Phase reversal</div>
          <div className="exploreListItem">- Suction pressure</div>
          <div className="exploreListItem">- Tank level</div>
        </div>
        <div className="exploreBodyText">
          Additionally, if your system has a flow meter with an analog output,
          your flow rate can be monitored.
        </div>
        <div className="exploreBodyText bold">
          For a dry system, Remote typically monitors:
        </div>
        <div className="exploreList">
          <div className="exploreListItem">- Compressor run status</div>
          <div className="exploreListItem">- Pressure</div>
          <div className="exploreListItem">- Compressor tripped status</div>
          <div className="margin-lr">(if aux contacts available)</div>
        </div>
        <h2>Why Choose Remote?</h2>
        <div className="exploreBodyText">
          There are numerous benefits to monitoring your equipment with Rosemont
          Remote.
        </div>
        <div className="exploreBodyText">
          For instance, if your fire pump activates while you're away, you'll
          receive an immediate text alert—ensuring that you're informed in real
          time.
        </div>
        <div className="exploreBodyText">
          Additionally, Rosemont Remote tracks and records every detail of your
          system's activity, offering valuable insights for troubleshooting. For
          example, you can easily determine how many times your jockey pump has
          run today and compare it to normal activity levels. You can even view
          run count history on a bar graph.
        </div>
        <div className="exploreBodyText">
          Check out the video below to see the run count bar graph.
        </div>
        <video controls loop playsInline className="exploreVideo">
          <source src={barGraphVideo} type="video/mp4" />
        </video>
        <div className="exploreBodyText">
          Remote always knows what your pressure is, alerting you if it goes
          below or above a certain value. It also records pressure logs to give
          you a comprehensive history of your pressure over time.
        </div>
        <div className="exploreBodyText">
          These pressure logs are available to view in a line chart, filterable
          by date and time.
        </div>
        <div className="exploreBodyText">
          Check out the video below to see the pressure line chart.
        </div>
        <video controls loop playsInline className="exploreVideo">
          <source src={lineChartVideo} type="video/mp4" />
        </video>
        <div className="exploreBodyText">
          In addition to the current status screen, run count graph and pressure
          chart, Remote includes an event log.
        </div>
        <div className="exploreBodyText">
          The event log provides a comprehensive history of your system's
          performance, including every pump run, pressure alert, and alarm.
        </div>
        <div className="exploreBodyText">
          The event log is filterable by date and message, making it easy to
          find certain events.
        </div>
        <h2>Does Rosemont Remote Offer Any Additional Features?</h2>
        <div className="exploreBodyText">
          Yes! Rosemont Remote also includes a digital filing cabinet to manage
          your equipment details such as model and serial numbers, contact
          information and equipment images.
        </div>
        <div className="exploreBodyText">
          It even has a folder system for you to create folders and upload
          pertinent documents.
        </div>
        <img className="widePic" src={docs} />
        {!emailSubmitted ? (
          <>
            <PulseLoader
              color={"#b9b9b9"}
              loading={loading}
              size={10}
              className="margin-t-b-2"
            />
            {!loading && (
              <>
                <h2 className="centeredText">Want To Learn More?</h2>
                <div className="exploreBodyText centeredText">
                  Join our email list for updates.
                </div>
                <div className="exploreInputLabel bold">Email:</div>
                <input
                  className="darkInputSmaller"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <div className="exploreInputLabel bold">First Name:</div>
                <input
                  className="darkInputSmaller"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
                <div className="exploreInputLabel bold">Last Name:</div>
                <input
                  className="darkInputSmaller"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
                <div className="margin-t-b-1">
                  Would you also like to request a sales call?
                </div>
                <div className="flex-space-out">
                  <div
                    className={
                      requestedSalesCall
                        ? "salesCallButtonGreen"
                        : "salesCallButton"
                    }
                    onClick={() => {
                      setRequestedSalesCall(true);
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={
                      requestedSalesCall === false
                        ? "salesCallButtonDark"
                        : "salesCallButton"
                    }
                    onClick={() => {
                      setRequestedSalesCall(false);
                    }}
                  >
                    No
                  </div>
                </div>
                {emailSignUpError && (
                  <div className="red margin-t-b-1">{emailSignUpError}</div>
                )}
                <ReCAPTCHA
                  sitekey="6Lf6XSsqAAAAAPOINgcI-eLuhVHcTYjg_EdRnoNV"
                  onChange={onCaptchaChange}
                />
                {captchaToken && (
                  <div onClick={signUpEmail} className="homeButton">
                    Submit
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <h2>Thanks for signing up!</h2>
            {requestedSalesCall ? (
              <div className="exploreBodyText centeredText">
                You've been added to our email list and we'll be in touch to
                schedule a sales call.
              </div>
            ) : (
              <div className="exploreBodyText centeredText">
                You've been added to our email list.
              </div>
            )}
          </>
        )}
        <div onClick={() => history.push("/")} className="margin-t-b-1 pointer">
          Go To Home
        </div>
      </div>
    </div>
  );
}
